.cs-logo {
  height: 50px;
}

.nav-bar {
  background-color: rgba(0,0,0,.03);
  border-top: 6px solid #3bc3b2;
}

.body {
  margin-top: 30px;
}

.card {
  border-left: 6px solid #3bc3b2;
  margin: 0 auto; 
  float: none; 
  margin-bottom: 10px; 
}